import React from "react"
import { graphql } from "gatsby"
import Seo from "gatsby-plugin-wpgraphql-seo"

import Layout from "../../assets/styles/layout"
import CallToAction from "../../components/global/CallToAction"
import Footer from "../../components/global/Footer"

import {
  FooterContainer,
  LineWrapperWithSpaces,
} from "../../components/global/common/containers"
import { Article } from "../../components/global/Article"

import DefaultHeader from "../../components/global/header/DefaultHeader"

export const query = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          srcSet
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          sourceUrl
          srcSet
        }
        canonical
        cornerstone
        schema {
          articleType
          pageType
          raw
        }
      }
      acfCallToAction {
        ctaPersonPosition
        ctaPersonName
        ctaForm
        ctaImage {
          altText
          atttachement_pl {
            altTextPl
          }
          sourceUrl
          srcSet
        }
        ctaContactOptions {
          eMail
          phone
        }
        ctaButtons {
          ctaButton1 {
            url
            title
            target
          }
          ctaButton2 {
            target
            url
            title
          }
        }
        ctaIntro
        ctaTitle
      }
      acfTextPage {
        header
        leadHeader
        leadText
      }
      content
    }
  }
`

const DefaultPage = ({ pageContext, data: { wpPage } }) => {
  const { header, leadHeader, leadText } = wpPage.acfTextPage
  const { content, seo } = wpPage

  return (
    <Layout>
      {seo && process.env.GATSBY_SITE_ENV === "production" && (
        <Seo post={wpPage} />
      )}
      <DefaultHeader title={header} pageContext={pageContext} />
      <LineWrapperWithSpaces as="main">
        <Article {...{ leadHeader, leadText, content }} fullWidth />
      </LineWrapperWithSpaces>
      <FooterContainer>
        <CallToAction />
        <Footer />
      </FooterContainer>
    </Layout>
  )
}

export default DefaultPage
